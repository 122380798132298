import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data-service";
import { BehaviorSubject } from "rxjs";
import { StatisticsFiltersService } from "../statistics-filters.service";
import { SubscriptionService } from "@app/core/services/subscription.service";

@Injectable({
    providedIn: "root",
})
export class StatisticsRevenueTotalsDataService extends DataService {
    fetch = new BehaviorSubject<boolean>(true);
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    total: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private statisticsFiltersService: StatisticsFiltersService,
        private subscritpionService: SubscriptionService
    ) {
        super(1);
    }

    init() {
        const dateFiltersSubscription = this.statisticsFiltersService.dateFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        const dataFiltersSubscription = this.statisticsFiltersService.dataFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        const revenueValueToDisplaySubscription = this.statisticsFiltersService.revenueValueToDisplay.subscribe({
            next: (x: any) => {
                if (x) {
                    this.formatData();
                }
            },
        });

        const dataSubscription = this.data.subscribe({
            next: (x: any) => {
                if (x) {
                    this.formatData();
                }
            },
        });

        const fetchSubscription = this.fetch.subscribe({
            next: (x: any) => {
                if (x) {
                    this.formatData();
                }
            },
        });

        this.subscritpionService.addSubscription(dateFiltersSubscription);
        this.subscritpionService.addSubscription(dataFiltersSubscription);
        this.subscritpionService.addSubscription(revenueValueToDisplaySubscription);
        this.subscritpionService.addSubscription(dataSubscription);
        this.subscritpionService.addSubscription(fetchSubscription);
    }

    formatData() {
        this.loadingData.next(true);

        if (this.data.value) {
            this.total.next(this.data.value[this.statisticsFiltersService.revenueValueToDisplay.value.value]);
            this.loadingData.next(false);
        }

    }
}
