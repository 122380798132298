export interface WithdrawRequestType {
  label: string;
  value: string;
  selected?: boolean;
}

export interface Currency {
  label: string;
  value: string;
  selected?: boolean;
}

export interface TransactionType {
  label: string;
  value: string;
  selected?: boolean;
}

export const COLORS: any = {
  purple: "#4700c0",
  pink: "#FD197C"
};

export const WITHDRAW_REQUEST_TYPES: WithdrawRequestType[] = [
 
  {
    label: "withdrawRequest.bankIban",
    value: "BANK-IBAN"
  },
  {
    label: "withdrawRequest.bankAccount",
    value: "BANK-ACCOUNT"
  },
  {
    label: "withdrawRequest.paypal",
    value: "PAYPAL"
  }
];


export const CURRENCIES: Currency[] = [
  {
    label: "wallet.currencies.dollar",
    value: "USD",
    selected : true
  },
  {
    label: "wallet.currencies.euro",
    value: "EUR",
    selected : false

  }
];


export const FIAT_TRANSFER_ACCOUNT_TYPES: Currency[] = [
  {
    label: "wallet.iban",
    value: "BANK-IBAN",
    selected: true
  },
  {
    label: "wallet.accountNumber",
    value: "BANK-ACCOUNT",
    selected: false

  }
];

export const TRANSACTION_TYPES: TransactionType[] = [
  {
    label: "wallet.types.all",
    value: "all"
  },
  {
    label: "wallet.types.debit",
    value: "debit"
  },
  {
    label: "wallet.types.credit",
    value: "credit"
  }
];

export const ALLOWED_PARTNER_ACCOUNT_ICONS: any = {
  ApkPure: "/assets/images/partners/apkpure.png",
  Aptoide: "/assets/images/partners/aptoide.svg",
  Aptoide1: "/assets/images/partners/aptoide.svg",
  Honor: "/assets/images/partners/honor.png",
  "Huawei": "/assets/images/partners/huawei.png",
  "Huawei Search": "/assets/images/partners/huawei.png",
  "HuaweiSearch": "/assets/images/partners/huawei.png",
  "One Store": "/assets/images/partners/one-store.png",
  oneStore: "/assets/images/partners/one-store.png",
  OPPO: "/assets/images/partners/oppo.png",
  QMobile: "/assets/images/stores/qmobile.png",
  Softonic: "/assets/images/partners/softonic.png",
  Softonic2: "/assets/images/partners/softonic.png",
  Softonic3: "/assets/images/partners/softonic.png",
  Softonic4: "/assets/images/partners/softonic.png",
  Transsion: "/assets/images/partners/transsion.png",
  UpToDown: "/assets/images/partners/uptodown.png",
  Xiaomi: "/assets/images/partners/xiaomi.png",
  rustore: "/assets/images/partners/rustore.png",
  oomph: "/assets/images/partners/oomph.png",
  xreal: "/assets/images/partners/XREAL.png",
  "gamesHub":"/assets/images/partners/gameshub.png"
};
