import { CommonModule, DecimalPipe } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { RouterModule } from "@angular/router";
import { AutocompleteModule } from "@app/shared/autocomplete/autocomplete.module";
import { ButtonModule } from "@app/shared/button/button.module";
import { ErrorBoxModule } from "@app/shared/error-box/error-box.module";
import { TotalPipe } from "@app/shared/pipes/pipes";
import { PipesModule } from "@app/shared/pipes/pipes.module";
import { LoadingSpinnerModule } from "@app/shared/spinner/loading-spinner.module";
import { TranslateModule } from "@ngx-translate/core";
import { NgClickOutsideDirective } from "ng-click-outside2";
import { ButtonDropdownModule } from "./button-dropdown/button-dropdown.module";
import { TooltipDirectiveModule } from "./tooltip/tooltip.directive.module";
import { AccountInformation } from "./account-information-form/account-information-form.module";
import { CopyToClipboardTextModule } from "./copy-to-clipboard-text/copy-to-clipboard-text.module";

@NgModule({
    imports: [
        ButtonDropdownModule,
        CopyToClipboardTextModule,
        PipesModule,
        NgClickOutsideDirective,
        MatDatepickerModule,
        MatNativeDateModule,
        MatExpansionModule,
        TooltipDirectiveModule,
        AutocompleteModule,
        RouterModule,
        ButtonModule,
        LoadingSpinnerModule,
        ErrorBoxModule,
        CommonModule,
        TranslateModule,
        AccountInformation,
    ],
    exports: [PipesModule, TooltipDirectiveModule, CommonModule, FormsModule, TranslateModule, ButtonDropdownModule, CopyToClipboardTextModule, MatExpansionModule],
    providers: [DecimalPipe, TotalPipe],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
        };
    }
}
