import { Injectable } from "@angular/core";
import { APIRequestsService, RequestParameters } from "@app/core/services/api.requests.service";
import { DataService } from "@app/core/services/data-service";
import { BehaviorSubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { StatisticsFiltersService } from "../statistics-filters.service";
import { DashboardTopTableEntry } from "../utils";
import { SubscriptionService } from "@app/core/services/subscription.service";

@Injectable({
    providedIn: "root",
})
export class StatisticsDownloadsTopAppsDataService extends DataService {
    fetch = new BehaviorSubject<boolean>(false);
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    topApps: BehaviorSubject<DashboardTopTableEntry[]> = new BehaviorSubject<DashboardTopTableEntry[]>([]);

    constructor(
        private statisticsFiltersService: StatisticsFiltersService, 
        private requestsService: APIRequestsService,
        private subscriptionService: SubscriptionService
    ) {
        super(1);
    }

    init() {
        const dateFiltersSubscription = this.statisticsFiltersService.dateFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        const dataFiltersSubscription = this.statisticsFiltersService.dataFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        const fetchSubscription = this.fetch
            .pipe(
                switchMap((x) => {
                    if (!x) {
                        return;
                    }
                    return this.getData();
                })
            )
            .subscribe({
                next: (data: any) => {
                    const top: DashboardTopTableEntry[] = [];
                    for (let key in data) {
                        top.push(
                            new DashboardTopTableEntry(
                                top.length + 1, 
                                data[key].identifiers.package.name, 
                                data[key].identifiers.package.title, 
                                data[key].total
                            )
                        );
                    }
                    this.topApps.next(top);
                    this.loadingData.next(false);
                },
                error: (_error: any) => {
                    this.topApps.next([]);
                    this.loadingData.next(false);
                },
            });
        
        this.subscriptionService.addSubscription(dateFiltersSubscription);
        this.subscriptionService.addSubscription(dataFiltersSubscription);
        this.subscriptionService.addSubscription(fetchSubscription);
    }

    getData() {
        this.loadingData.next(true);
        const parameters: RequestParameters[] = [
            ...this.statisticsFiltersService.getDateFiltersRequestHeaders(),
            ...this.statisticsFiltersService.getDataFiltersRequestHeaders(false),
            {
                label: "type",
                data: "downloads",
            },
            {
                label: "groupBy",
                data: "package",
            },
            {
                label: "sort",
                data: "revenue",
            },
            {
                label: "order",
                data: "DESC",
            },
            {
                label: "limit",
                data: 100,
            },
            {
                label: "offset",
                data: 0,
            },
        ];
        return this.requestsService.get("/api/statistics/top", parameters);
    }

    getDataForCSV(limit: number, offset: number) {
        const parameters: RequestParameters[] = [
            ...this.statisticsFiltersService.getDateFiltersRequestHeaders(),
            ...this.statisticsFiltersService.getDataFiltersRequestHeaders(false),
            {
                label: "type",
                data: "downloads",
            },
            {
                label: "groupBy",
                data: "package",
            },
            {
                label: "sort",
                data: "revenue",
            },
            {
                label: "order",
                data: "DESC",
            },
            {
                label: "limit",
                data: limit,
            },
            {
                label: "offset",
                data: offset,
            },
        ];
        return this.requestsService.get("/api/statistics/top", parameters,true);
    }
}
