import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeavingConfirmationModalComponent } from './leaving-confirmation-modal.component';
import { ButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LeavingConfirmationModalComponent],
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule
  ],
  exports:[LeavingConfirmationModalComponent]
})
export class LeavingConfirmationModalModule { }
