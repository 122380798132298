import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { getDomainCookie, getPortalUrl } from "./utils";

@Injectable()
export class UserService {
    loggedUser = new BehaviorSubject<any>({});
    loggedAccount = new BehaviorSubject<any>({});
    welcomeSettingsOpened = new BehaviorSubject<boolean>(false);
    _isRecoverableEmail = new Subject<any>();
    _lastIntercomUpdate: any;
    loadingUserBasicInformation = new BehaviorSubject<boolean>(true);
    loadingAccountsList = new BehaviorSubject<boolean>(true);
    requiresPasswordValidation = new BehaviorSubject<boolean>(true);
    selectedAccountUid: string;
    selectedAccountName: string;
    accountsExtraInfo = new BehaviorSubject<any>([]);
    selectedAccount = new BehaviorSubject<any>(null);
    accountsSums = new BehaviorSubject<any>({
        transactions: 0,
        netRevenue: 0,
    });

    constructor(private http: HttpClient, private _cookieService: CookieService, private translateService: TranslateService) {}

    loadUserBasicInformation() {
        this.loadingUserBasicInformation.next(true);
        const url = environment.baseNodeUrlServices + "/api/session?nocache";
        this.http.get(url).subscribe({
            next: (resp: any) => {
                const _user = resp.user;
                const _account = resp.account;
                const _accountUser = resp.accountUser;
                this.selectedAccountUid = _account.uid;
                this.selectedAccountName = _account.name;

                if (_account.isPartner && !_account.isInfluencer && !_account.settings["store.termsAndConditionsPhysicallySigned"] && !_account.settings["store.termsAndConditions"]) {
                    window.location.href = getPortalUrl();
                } else {
                    let user = {
                        permissions: _accountUser.permissions,
                        allowedPackagesName: _accountUser.packageNames,
                        roles: _accountUser.roles,
                        name: _user.name,
                        email: _user.emailAddress,
                        origin: _user.origin,
                        username: _user.emailAddress,
                        phoneNumber: _user.phoneNumber,
                        signupTimestamp: _account ? _account.signupTimestamp : "",
                        loginConfirmationMethod: _user.settings.loginConfirmationMethod,
                        weChatUid: _user.settings.weChatUid,
                        withdrawRequestConfirmationMethod: _user.settings.withdrawRequestConfirmationMethod,
                        currency: _user.settings.currency ? _user.settings.currency : "USD",
                        uid: _user.uid,
                        accountUid: _account.uid,
                        type: _user.isDeveloper ? "developer" : "store",
                        isSuperAdmin: _user.isAdmin,
                        withdrawInfo: {
                            recipientWalletAddress: _user.settings["withdrawAppc.wallet"],
                            accountHolderCountry: _user.settings["withdrawFiat.holderCountry"],
                            accountHolderAddress: _user.settings["withdrawFiat.holderAddress"],
                            accountHolderZipCode: _user.settings["withdrawFiat.holderZipCode"],
                            accountHolderState: _user.settings["withdrawFiat.holderState"],
                            accountHolderName: _user.settings["withdrawFiat.holderName"],
                            accountHolderCity: _user.settings["withdrawFiat.holderCity"],
                            accountCurrency: _user.settings["withdrawFiat.currency"],
                            bicSwiftCode: _user.settings["withdrawFiat.bic"],
                            iban: _user.settings["withdrawFiat.iban"],
                            paypalAccount: _user.settings["withdrawFiat.paypalAccount"],
                            vat: _user.settings.vat,
                        },
                    };

                    this.translateService.setDefaultLang("en_GB");
                    this.translateService.use("en_GB").subscribe();
                    this.requiresPasswordValidation.next(!["google", "facebook"].includes(_user.origin));

                    this.loggedUser.next(user);

                    _account.settings.uid = _account.uid;
                    _account.settings.deletionRequest = _account.deletionRequest;
                    _account.settings.ownerUserEmailAddress = _account.ownerUser.emailAddress;
                    _account.settings.isInfluencer = _account.isInfluencer;
                    _account.settings.isPartner = _account.isPartner && !_account.isInfluencer;
                    _account.settings.promoCode = _account.settings["influencer.promoCode"] ? _account.settings["influencer.promoCode"] : null;
                    _account.settings.hideWallet = _account.settings.hideWallet ? _account.settings.hideWallet : false;
                    _account.settings.isPartnerWithoutRevenueShare = _account.settings["partner.revenueShare"] === 0 ? true : false;
                    _account.settings.showApiKeysWidget = (() => {
                        if (["store", "oem"].some((type) => _account.settings.types.includes(type))) {
                            if (_account.settings["partner.hideApiKeys"]) {
                                return false;
                            }
                            return true;
                        }

                        return false;
                    })();

                    this.loggedAccount.next(_account.settings);
                }

                this.loadingUserBasicInformation.next(false);
            },
            error: (error: any) => {
                this.loadingUserBasicInformation.next(false);

                if (error.status === 403) {
                    window.location.href = getPortalUrl();
                }
            },
        });
    }

    getPartnerExtraInfo(userUid: any) {
        this.loadingAccountsList.next(true);

        this.http.get(`${environment.baseNodeUrlServices}/api/dashboard/partners?userUid=${userUid}`).subscribe({
            next: (resp: any) => {
                this.accountsExtraInfo.next(resp);

                if (resp.length === 1) {
                    this.selectedAccount.next(resp[0]);
                }

                if (resp.length > 1 && resp.every((account) => !!account.last7DaysDownloadsTotals) && resp.every((account) => !!account.last7DaysTransactionsTotals)) {
                    this.accountsSums.next({
                        downloads: resp.reduce((sum, account) => sum + account?.last7DaysDownloadsTotals?.total, 0),
                        transactions: resp.reduce((sum, account) => sum + account?.last7DaysTransactionsTotals?.transactions, 0),
                        netRevenue: resp.reduce((sum, account) => sum + account?.last7DaysTransactionsTotals?.raw?.usd_credit, 0),
                    });
                }

                this.loadingAccountsList.next(false);
            },
            error: (error: any) => {
                this.loadingAccountsList.next(false);
            },
        });
    }

    getInfluencerExtraInfo(userUid: any) {
        this.loadingAccountsList.next(true);

        this.http.get(`${environment.baseNodeUrlServices}/api/dashboard/influencers?userUid=${userUid}`).subscribe({
            next: (resp: any) => {
                resp.map((influencerExtraInfo) => {
                    if (influencerExtraInfo && influencerExtraInfo.promoCodeEndDate) {
                        influencerExtraInfo["isActive"] = Date.parse(influencerExtraInfo.promoCodeEndDate) > Date.now();
                    }
                    if (influencerExtraInfo && influencerExtraInfo.promoCodePackageNames && influencerExtraInfo.promoCodePackageNames.length) {
                        influencerExtraInfo["package"] = influencerExtraInfo.promoCodePackageNames[0];
                    }
                });

                this.accountsExtraInfo.next(resp);

                if (resp.length === 1) {
                    this.selectedAccount.next(resp[0]);
                }

                if (resp.length > 1 && resp.every((account) => !!account.last7DaysTransactionsTotals)) {
                    this.accountsSums.next({
                        transactions: resp.reduce((sum, account) => sum + account?.last7DaysTransactionsTotals?.transactions, 0),
                        netRevenue: resp.reduce((sum, account) => sum + account?.last7DaysTransactionsTotals?.raw?.usd_credit, 0),
                    });
                }

                this.loadingAccountsList.next(false);
            },
            error: (error: any) => {
                this.loadingAccountsList.next(false);
            },
        });
    }

    logout() {
        this.loggedUser.next(null);
        this._cookieService.remove(environment.catappultTokenCookie, {
            domain: getDomainCookie(),
            secure: environment.production,
        });
        window.sessionStorage.setItem("isLogout", "true");
    }

    sendUrlLog(url: string) {
        this.http.post(`${environment.baseNodeUrlServices}/api/utils/urlLogs`, { url }).subscribe();
    }

    setFeedback(message: string) {
        const url = environment.baseNodeUrlServices + "/api/intercom/conversationMessage";
        return this.http.post(url, { message });
    }

    intercom(value, step: string) {
        if (typeof (<any>window).Intercom !== "undefined") {
            const intercomSettings = {
                app_id: "t3lrdtj5",
                name: this.loggedUser?.value?.name, // Full name
                email: this.loggedUser?.value?.username, // Login Email
                custom_launcher_selector: "#intercom-custom-launcher",
                alignment: "right",
                horizontal_padding: 1,
                vertical_padding: 1,
            };
            if (step === "add_page") {
                intercomSettings["add_app_page_visit"] = value;
            }
            (<any>window).intercomSettings = intercomSettings;
            (<any>window).Intercom("update");
        }
    }

    setlastIntercomUpdate(timestamp: any) {
        this._lastIntercomUpdate = timestamp;
    }

    get lastIntercomUpdate(): any {
        return this._lastIntercomUpdate;
    }
}
