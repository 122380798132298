import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { APIRequestsService, RequestParameters } from "@app/core/services/api.requests.service";
import { AutocompleteEntry } from "@app/shared/autocomplete/autocomplete.component";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AppsListService {
    loadingAppsListForAutocompleteData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    autocompleteData: BehaviorSubject<AutocompleteEntry[]> = new BehaviorSubject<AutocompleteEntry[]>([]);

    appsListFilter: BehaviorSubject<String> = new BehaviorSubject<string>("");

    constructor(private requestsService: APIRequestsService, private router: Router) {
        this.getAppsWithApprovedVersionsListForAutocompleteData();
    }

    getAppsWithApprovedVersionsListForAutocompleteData() {
        const parameters: RequestParameters[] = [];
        parameters.push({ label: "limit", data: "500" });
        parameters.push({ label: "withApprovedVersions", data: "true" });
        parameters.push({ label: "accountApplicationStatus", data: "APPROVED" });

        this.requestsService.get(`/api/applications`, parameters).subscribe({
            next: (res: any) => {
                const autocompleteData = [];
                res.forEach((element) => {
                    autocompleteData.push({
                        id: element?.application?.name,
                        value: element?.application?.name,
                        label: element?.accountApplication?.extras?.title,
                        iconUrl: element?.accountApplication?.extras?.iconUrl,
                    } as AutocompleteEntry);
                });
                autocompleteData.sort((a, b) => {
                    return a?.label < b?.label ? -1 : a?.label > b?.label ? 1 : 0;
                });
                this.autocompleteData.next(autocompleteData);
                this.loadingAppsListForAutocompleteData.next(false);
            },
        });
    }
}
