import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PermissionsService } from "./permissions.service";
import { UserService } from "./user.service";
import { StatisticsMobileFiltersService } from "@app/modules/dashboard/account-details/statistics/components/mobile-filters/statistics-mobile-filters.service";
import { StatisticsDownloadsChartDataService } from "@app/modules/dashboard/account-details/statistics/services/downloads/chart-data.service";
import { StatisticsDownloadsTopAppsDataService } from "@app/modules/dashboard/account-details/statistics/services/downloads/top-apps-data.service";
import { StatisticsDownloadsTopCountriesDataService } from "@app/modules/dashboard/account-details/statistics/services/downloads/top-countries-data.service";
import { StatisticsDownloadsTotalsDataService } from "@app/modules/dashboard/account-details/statistics/services/downloads/totals-data.service";
import { StatisticsRevenueChartDataService } from "@app/modules/dashboard/account-details/statistics/services/revenue/chart-data.service";
import { StatisticsRevenueTopAppsDataService } from "@app/modules/dashboard/account-details/statistics/services/revenue/top-apps-data.service";
import { StatisticsRevenueTopCountriesDataService } from "@app/modules/dashboard/account-details/statistics/services/revenue/top-countries-data.service";
import { StatisticsRevenueTotalsDataService } from "@app/modules/dashboard/account-details/statistics/services/revenue/totals-data.service";
import { StatisticsFiltersService } from "@app/modules/dashboard/account-details/statistics/services/statistics-filters.service";
import { CacheService } from "./cache.service";
import { WalletMainService } from "@app/modules/dashboard/account-details/wallet/main/main.service";
import { WalletTransactionsListService } from "@app/modules/dashboard/account-details/wallet/transactions-list/transactions-list.service";
import { WalletFiltersService } from "@app/modules/dashboard/account-details/wallet/filters/filters.service";
import { WalletMobileFiltersService } from "@app/modules/dashboard/account-details/wallet/mobile-filters/wallet-mobile-filters.service";
import { SubscriptionService } from "./subscription.service";

@Injectable()
export class InitService {
    constructor(
        private userService: UserService, 
        private router: Router, 
        private permissionService: PermissionsService, 
        public dialog: MatDialog,
        private statisticsDownloadsChartDataService: StatisticsDownloadsChartDataService,
        private statisticsDownloadsTopAppsDataService: StatisticsDownloadsTopAppsDataService,
        private statisticsDownloadsTopCountriesDataService: StatisticsDownloadsTopCountriesDataService,
        private statisticsDownloadsTotalsDataService: StatisticsDownloadsTotalsDataService,
        private statisticsRevenueChartDataService: StatisticsRevenueChartDataService,
        private statisticsRevenueTopAppsDataService: StatisticsRevenueTopAppsDataService,
        private statisticsRevenueTopCountriesDataService: StatisticsRevenueTopCountriesDataService,
        private statisticsRevenueTotalsDataService: StatisticsRevenueTotalsDataService,
        private statisticsFiltersService: StatisticsFiltersService,
        private statisticsMobileFiltersService: StatisticsMobileFiltersService,
        private walletFiltersService: WalletFiltersService,
        private walletMobileFiltersService: WalletMobileFiltersService,
        private walletMainService: WalletMainService,
        private walletTransactionsListService: WalletTransactionsListService,
        private cacheService: CacheService,
        private subscriptionService: SubscriptionService
    ) {}

    init() {
        this.userService.loadUserBasicInformation();
        return new Promise<void>((resolve, reject) => {
            this.userService.loadingUserBasicInformation.subscribe((res) => {
                if (!res) {
                    this.permissionService.allowedPlaces();
                    if (!window.location.href.split("/").some((r) => this.permissionService.allowedPaths.includes(r))) {
                        this.router.navigate([this.permissionService.allowedPaths[0]]);
                    }
                    resolve();
                }
            });
        });
    }
    
    resetServices() {
        this.cacheService.removeCacheEntries("/api/statistics");
        this.cacheService.removeCacheEntries("/api/applications");
        this.cacheService.removeCacheEntries("/api/wallet");

        this.subscriptionService.unsubscribeAll();

        this.statisticsFiltersService.reset();
        this.statisticsMobileFiltersService.reset();

        this.statisticsRevenueChartDataService.init();
        this.statisticsRevenueTopAppsDataService.init();
        this.statisticsRevenueTopCountriesDataService.init();
        this.statisticsRevenueTotalsDataService.init();

        this.statisticsDownloadsChartDataService.init();
        this.statisticsDownloadsTopAppsDataService.init();
        this.statisticsDownloadsTopCountriesDataService.init();
        this.statisticsDownloadsTotalsDataService.init();

        this.walletFiltersService.reset();
        this.walletMobileFiltersService.reset();
        this.walletMainService.reset();
        this.walletTransactionsListService.reset();
        
    }
}
