import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data-service";
import { SubscriptionService } from "@app/core/services/subscription.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class StatisticsDownloadsTotalsDataService extends DataService {
    fetch = new BehaviorSubject<boolean>(true);
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    data: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor() {
        super(1);
    }
}
