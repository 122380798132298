import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data-service";
import { APIRequestsService } from "@app/core/services/api.requests.service";
import { BehaviorSubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { StatisticsFiltersService } from "../statistics-filters.service";
import { DataSetEntry } from "../utils";
import { StatisticsDownloadsTotalsDataService } from "./totals-data.service";
import { SubscriptionService } from "@app/core/services/subscription.service";

@Injectable({
    providedIn: "root",
})
export class StatisticsDownloadsChartDataService extends DataService {
    private dataLabels: string[] = ["statistics.installs", "statistics.updates"];
    fetch = new BehaviorSubject<boolean>(false);
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    labels: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    datasets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(
        public requestsService: APIRequestsService,
        public statisticsFiltersService: StatisticsFiltersService,
        private statisticsDownloadsTotalsDataService: StatisticsDownloadsTotalsDataService,
        private subscriptionService: SubscriptionService
    ) {
        super(1);
    }

    init() {

        const dateFiltersSubscription = this.statisticsFiltersService.dateFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        const dataFiltersSubscription = this.statisticsFiltersService.dataFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        const fetchSubscription = this.fetch
            .pipe(
                switchMap((x) => {
                    if (!x) {
                        return;
                    }
                    return this.getData();
                })
            )
            .subscribe({
                next: (data: any) => {

                    if (
                        !data ||
                        !data.totals ||
                        (!data.totals.total && data.totals.total !== 0) ||
                        !data.datasets ||
                        !data.datasets.length ||
                        !data.labels ||
                        !data.labels.length
                    ) {
                        this.loadingData.next(false);
                        return;
                    }

                    this.statisticsDownloadsTotalsDataService.data.next({total: data.totals.total});

                    const labels = data["labels"];
                    const datasets = data.datasets.map((_element: any, _index: number) => {
                        const label = _element.label;
                        const values = [];
                        _element.data.forEach((dataItem) => {
                            values.push(dataItem.total);
                        });
                        return new DataSetEntry("Downloads", values);
                    });
                    this.labels.next(labels);
                    this.datasets.next(datasets);
                    this.loadingData.next(false);
                    this.statisticsDownloadsTotalsDataService.loadingData.next(false);
                },
                error: (_error: any) => {
                    this.labels.next([]);
                    this.datasets.next([]);
                    this.loadingData.next(false);
                    this.statisticsDownloadsTotalsDataService.loadingData.next(false);
                },
            });

        this.subscriptionService.addSubscription(dateFiltersSubscription);
        this.subscriptionService.addSubscription(dataFiltersSubscription);
        this.subscriptionService.addSubscription(fetchSubscription);
    }

    getData() {
        this.loadingData.next(true);
        this.statisticsDownloadsTotalsDataService.loadingData.next(true);
        
        return this.getChart();
    }

    getChart() {
        return this.requestsService.get("/api/statistics/chart", [
            ...this.statisticsFiltersService.getDateFiltersRequestHeaders(),
            ...this.statisticsFiltersService.getDataFiltersRequestHeaders(false),
            {
                label: "type",
                data: "downloads",
            },
        ]);
    }
}
