
import { Observable, throwError as observableThrowError, catchError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { environment } from '@env/environment';
import { UserService } from '@app/core/services/user.service';
import { getDomainCookie, getPortalUrl } from '@app/core/services/utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _cookieService: CookieService,
    private userService: UserService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = new HttpHeaders();

    if (this._cookieService.get(environment.catappultTokenCookie)) {
      headers = headers.set('Authorization', `Bearer ${this._cookieService.get(environment.catappultTokenCookie)}`);
    } else {
      this.buildProtectedUrlRedirect();
      window.location.href = `${getPortalUrl()}/login`;
    }

    req = req.clone({
      headers: headers
    });

    return next.handle(req).pipe(catchError(err => this.handleError(err)));
  }

  private handleError(err: any): Observable<any> {
    let errorMsg;
    if (err.error instanceof Error) {
        errorMsg = [`An error occurred: ${err.error.message}`];
    } else {
        errorMsg = {
          status: err.status,
          error: err.error
        };
    }
    if (err.status === 401) {
      errorMsg = 'Authentication failure';
      this.userService.logout();
      window.location.href = `${getPortalUrl()}/login`;
    } else if (err.status === 403) {}

    //TODO: fix deprecated
    return observableThrowError(errorMsg);
}

  buildProtectedUrlRedirect() {
    if (!window.sessionStorage.getItem('isLogout')) {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      this._cookieService.put('protect_url_redirect', window.location.href, {
        domain: getDomainCookie(),
        expires: tomorrow,
      });
    } else {
      window.sessionStorage.clear();
    }
  }
}
