import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { isClientSideExecution } from "./utils";

@Injectable({
    providedIn: "root",
})
export class IndicativeService {
    sentEvents: string[] = [];

    constructor() {}

    sendEvent(name: string, uid?: string, data?: any) {
        if (!isClientSideExecution()) {
            return;
        }

        if (!environment.production) {
            console.log(`catappult_${name}`)
            return;
        }

        if ((<any>window).Indicative === undefined) {
            return;
        }

        if (uid && data) {
            (<any>window).Indicative.buildEvent(`catappult_${name}`, uid, data);
            return;
        }

        if (this.sentEvents.includes(name)) {
            return;
        }

        this.sentEvents.push(name);
        (<any>window).Indicative.buildEvent(`catappult_${name}`);
    }
}
