import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { APIRequestsService } from "@app/core/services/api.requests.service";
import { Country } from "@app/core/services/countries.service";
import { getFlagUrl } from "@app/core/services/utils.service";
import { environment } from "@env/environment";
import * as moment from "moment";
import { Observable } from "rxjs";

export interface App {
    name: string;
    packageName: string;
    iconUrl: string;
}

@Pipe({
    name: "total",
})
export class TotalPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe) {}
    transform(value: any, useAbbreviations: boolean = true, decimalPlaces: number = 2, absoluteValue: boolean = false): any {
        if (!value) {
            return this.decimalPipe.transform(0, `1.${decimalPlaces}-${decimalPlaces}`);
        }

        const formattedValue: any = (() => {
            if (absoluteValue) {
                return Math.abs(value);
            }

            return value;
        })();

        if (useAbbreviations) {
            const absoluteValue: any = Math.abs(formattedValue);
            if (absoluteValue >= 10000000) {
                return this.decimalPipe.transform(formattedValue / 1000000, "1.0-0") + "M";
            } else if (absoluteValue >= 1000000) {
                return this.decimalPipe.transform(formattedValue / 1000000, "1.1-1") + "M";
            } else if (absoluteValue >= 10000) {
                return this.decimalPipe.transform(formattedValue / 1000, "1.0-0") + "K";
            } else if (absoluteValue >= 1000) {
                return this.decimalPipe.transform(formattedValue / 1000, "1.1-1") + "K";
            } else if (absoluteValue >= 10) {
                return this.decimalPipe.transform(formattedValue, `1.${decimalPlaces}-${decimalPlaces}`);
            }
        }

        return this.decimalPipe.transform(formattedValue, `1.${decimalPlaces}-${decimalPlaces}`);
    }
}

@Pipe({
    name: "isCurrency",
})
export class IsCurrencyPipe implements PipeTransform {
    transform(unit): boolean {
        return unit && (unit.toLocaleLowerCase() === "appc" || unit.toLocaleLowerCase() === "usd");
    }
}

@Pipe({
    name: "catappultDate",
    pure: false,
})
export class CatappultDatePipe implements PipeTransform {
    transform(value: any): any {
        return moment(value).format("DD/MM/yyyy");
    }
}

@Pipe({
    name: "catappultTime",
    pure: false,
})
export class CatappultTimePipe implements PipeTransform {
    transform(value: any): any {
        return moment(value).format("HH:mm:ss");
    }
}
@Pipe({
    name: "catappultDateAndTime",
    pure: false,
})
export class CatappultDateAndTimePipe implements PipeTransform {
    transform(value: any): any {
        return moment(value).format("DD/MM/yyyy HH:mm:ss");
    }
}

@Pipe({
    name: "displayType",
})
export class DisplayTypePipe implements PipeTransform {
    transform(type: string): string {
        if (type) {
            switch (type.toLocaleLowerCase()) {
                case "iap":
                    return "wallet.transactionTypes.inAppPurchase";
                case "eskills":
                    return "wallet.transactionTypes.eskills";
                case "topup":
                    return "wallet.transactionTypes.topup";
                case "withdraw":
                    return "wallet.transactionTypes.withdraw";
                case "fee":
                    return "wallet.transactionTypes.fee";
                case "revertediap":
                    return "wallet.transactionTypes.revertedInAppPurchase";
                case "refundediap":
                    return "wallet.transactionTypes.refundedInAppPurchase";
                case "subscription":
                    return "wallet.transactionTypes.subscription";
                case "revertedsubscription":
                    return "wallet.transactionTypes.revertedSubscription";
                case "refundedsubscription":
                    return "wallet.transactionTypes.refundedSubscription";
                case "transfer":
                    return "wallet.transactionTypes.subscriptionPayment";
                case "custompayment":
                    return "wallet.transactionTypes.customPayment";
                default:
                    break;
            }
        }

        return "Unknown";
    }
}

@Pipe({
    name: "toLocaleStringValue",
})
export class ToLocaleStringPipe implements PipeTransform {
    transform(value: any, nrDecimals: number): string {
        return value
            ? value.toLocaleString(undefined, {
                  minimumFractionDigits: nrDecimals,
                  maximumFractionDigits: nrDecimals,
              })
            : "";
    }
}

@Pipe({
    name: "isDebit",
})
export class IsDebitPipe implements PipeTransform {
    transform(type: string): boolean {
        return (
            type &&
            [
                "withdraw",
                "fee",
                "refundediap",
                "revertediap",
                "revertedsubscription",
                "refundedsubscription",
                "transfer",
                "withdrawwallet",
                "withdrawpaypal",
                "withdrawbankaccount",
                "withdrawbankiban",
                "unknownwithdraw",
                "withdrawAppcoinsCredits",
            ].includes(type.toLowerCase())
        );
    }
}

@Pipe({
    name: "isApproved",
})
export class IsApprovedPipe implements PipeTransform {
    transform(status: string): boolean {
        return status && ["completed", "cancelled"].includes(status.toLowerCase());
    }
}

@Pipe({
    name: "isPending",
})
export class IsPendingPipe implements PipeTransform {
    transform(status: string): boolean {
        return status && ["pending", "pendingapproval"].includes(status.toLowerCase());
    }
}

@Pipe({
    name: "isRejected",
})
export class IsRejectedPipe implements PipeTransform {
    transform(status: string): boolean {
        return status && ["cancelling", "rejected"].includes(status.toLowerCase());
    }
}

@Pipe({
    name: "showPackageInfo",
})
export class ShowPackageInfoPipe implements PipeTransform {
    transform(type: string): boolean {
        return type && ["iap", "subscription", "revertediap", "refundediap", "revertedsubscription", "refundedsubscription"].includes(type.toLowerCase());
    }
}

@Pipe({
    name: "countryCodeFlagIconUrl",
})
export class CountryCodeFlagIconUrlPipe implements PipeTransform {
    transform(countryCode: string): string {
        return getFlagUrl(countryCode);
    }
}

@Pipe({
    name: "iconByUid",
})
export class IconByUidPipe implements PipeTransform {
    transform(uid: string, accounts: any[]): string {
        if (!uid || !accounts) {
            return "/assets/images/unknown-square.svg";
        }

        const account = accounts.find((x) => x.uid.includes(uid));
        return account ? account.icon : "/assets/images/unknown-square.svg";
    }
}

@Pipe({
    name: "partnerDisplayName",
})
export class PartnerDisplayNamePipe implements PipeTransform {
    transform(item: any, partners: any[]): string {
        if (!item || !partners.length) {
            return "Unknown";
        }

        const partner = partners.find((partner) => partner.uid.includes(item.name));

        return partner ? partner.label : item.label;
    }
}

@Pipe({
    name: "countryIcon",
})
export class CountryIconPipe implements PipeTransform {
    transform(_id: string, _values: Country[]): string {
        const value = _values.find((x) => x?.id?.toLowerCase() === _id?.toLowerCase());
        return value ? value.iconUrl : null;
    }
}

@Pipe({
    name: "countryName",
})
export class CountryNamePipe implements PipeTransform {
    transform(_id: string, _values: Country[]): string {
        const value = _values.find((x) => x?.id?.toLowerCase() === _id?.toLowerCase());
        return value ? value.name : _id;
    }
}

@Pipe({
    name: "appIcon",
})
export class AppIconPipe implements PipeTransform {
    transform(packageName: string): string {
        return `${environment.baseNodeUrlServices}/api/applications/${packageName}/icon`;
    }
}

@Pipe({
    name: "appTitle",
})
export class AppTitlePipe implements PipeTransform {
    constructor(private requestService: APIRequestsService) {}

    transform(packageName: string): Observable<any> {
        return this.requestService.get(`/api/applications/${packageName}/title`);
    }
}

@Pipe({
    name: "appName",
})
export class AppNamePipe implements PipeTransform {
    transform(packageName: string, _values: App[]): string {
        const value = _values.find((x) => x.packageName === packageName);
        return value ? value.name : packageName;
    }
}

@Pipe({
    name: "currencySymbol",
})
export class CurrencySimbolPipe implements PipeTransform {
    transform(currency: string): string {
        if (!currency) {
            return "";
        }
        if (currency?.toLowerCase() === "appc") {
            return "APPC";
        }
        return (0).toLocaleString("en", { style: "currency", currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, "").trim();
    }
}

@Pipe({
    name: "showIfDateMonthAndYearAreDiff",
})
export class ShowIfDateMonthAndYearAreDiff implements PipeTransform {
    transform(fromDate: Date, toDate: Date): boolean {
        if (!fromDate || !toDate) {
            return false;
        }

        if (toDate.getMonth() != fromDate.getMonth() || toDate.getFullYear() != fromDate.getFullYear()) {
            return true;
        }

        return false;
    }
}

@Pipe({
    name: "showIfUserHasPermission",
})
export class ShowIfUserHasPermission implements PipeTransform {
    transform(permissionList: string[], permission: string): boolean {
        if (!permissionList || !permissionList.length || !permission) {
            return false;
        }

        return permissionList.includes(permission);
    }
}
