import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { StatisticsFiltersService } from "../statistics-filters.service";
import { switchMap } from "rxjs/operators";
import { APIRequestsService } from "@app/core/services/api.requests.service";
import { DataService } from "@app/core/services/data-service";
import { DataSetEntry } from "../utils";
import { StatisticsRevenueTotalsDataService } from "./totals-data.service";
import { CurrencySimbolPipe, TotalPipe } from "@app/shared/pipes/pipes";
import { SubscriptionService } from "@app/core/services/subscription.service";

@Injectable({
    providedIn: "root",
})
export class StatisticsRevenueChartDataService extends DataService {
    fetch = new BehaviorSubject<boolean>(false);
    fetchOnlyChart = new BehaviorSubject<boolean>(false);
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    labels: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    datasets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(
        private statisticsFiltersService: StatisticsFiltersService,
        private requestsService: APIRequestsService,
        private statisticsRevenueTotalsDataService: StatisticsRevenueTotalsDataService,
        private totalPipe: TotalPipe,
        private currencySimbolPipe: CurrencySimbolPipe,
        private subscriptionService: SubscriptionService
    ) {
        super(1);
    }

    init() {
        const dateFiltersSubscription = this.statisticsFiltersService.dateFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        const dataFiltersSubscription = this.statisticsFiltersService.dataFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        const revenueAggregationSubscription = this.statisticsFiltersService.revenueAggregationSelectedOption.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        const revenueValuesToDisplaySubscription = this.statisticsFiltersService.revenueValueToDisplay.subscribe({
            next: (x: any) => {
                if (x) {
                    this.formatData();
                }
            },
        });

        const fetchSubscription = this.fetch
            .pipe(
                switchMap((x) => {   
                    
                    if (!x) {
                        return;
                    }

                    if (this.fetchOnlyChart.value === true) {
                        this.fetchOnlyChart.next(false);

                        return this.getData(false);
                    }
                    
                    this.data.next(null);
                    this.statisticsRevenueTotalsDataService.data.next(null);
                    return this.getData();
                })
            )
            .subscribe({
                next: (data: any) => {
                    if (
                        !data ||
                        !data.totals ||
                        !data.datasets ||
                        !data.datasets.length ||
                        !data.labels ||
                        !data.labels.length
                    ) {
                        this.loadingData.next(false);
                        return;
                    }

                    this.statisticsRevenueTotalsDataService.data.next(data.totals);
                    this.completeRevenueValuesSelect(data.totals);
                    this.data.next(data);

                    const labels = data.labels.map((_element: any) => {
                        if (this.statisticsFiltersService.revenueAggregationSelectedOption?.value?.value === "month") {
                            return _element
                                .split("-")
                                .slice(0, _element.split("-").length - 1)
                                .join("-");
                        }
                        return _element;
                    });

                    const datasets = data.datasets.map((_element: any, _index: number) => {
                        const values = [];
                        _element.data.forEach((dataItem) => {
                            values.push(dataItem[this.statisticsFiltersService.revenueValueToDisplay.value.value]);
                        });
                        return new DataSetEntry("Revenue", values);
                    });

                    this.labels.next(labels);
                    this.datasets.next(datasets);
                    this.loadingData.next(false);
                },
                error: (_error: any) => {
                    this.labels.next([]);
                    this.datasets.next([]);
                    this.loadingData.next(false);
                },
            });

        this.subscriptionService.addSubscription(dateFiltersSubscription);
        this.subscriptionService.addSubscription(dataFiltersSubscription);
        this.subscriptionService.addSubscription(revenueAggregationSubscription);
        this.subscriptionService.addSubscription(revenueValuesToDisplaySubscription);
        this.subscriptionService.addSubscription(fetchSubscription);
    }

    formatData() {
        this.loadingData.next(true);

        if (this.data.value) {
            const labels = this.data.value["labels"];
            const datasets = this.data.value.datasets.map((_element: any, _index: number) => {
                const label = _element.label;
                const values = [];
                _element.data.forEach((dataItem) => {
                    values.push(dataItem[this.statisticsFiltersService.revenueValueToDisplay.value.value]);
                });
                return new DataSetEntry("Revenue", values);
            });

            this.labels.next(labels);
            this.datasets.next(datasets);
        }
        this.loadingData.next(false);
    }

    getData(loadTotals = true) {
        this.loadingData.next(true);
        if (loadTotals) {
            this.statisticsRevenueTotalsDataService.loadingData.next(true);
        }
        
        return this.getChart();
    }

    getChart() {
        return this.requestsService.get("/api/statistics/chart", [
            ...this.statisticsFiltersService.getDateFiltersRequestHeaders(),
            ...this.statisticsFiltersService.getDataFiltersRequestHeaders(true),
            {
                label: "type",
                data: "combinedRevenue",
            },
        ]);
    }

    completeRevenueValuesSelect(data) {
        if (!data) {
            return;
        }
        for (let i = 0; i < this.statisticsFiltersService.revenueValuesToDisplay.length; i++) {
            let value = data[this.statisticsFiltersService.revenueValuesToDisplay[i].value];

            if (["grossVolume", "volume", "arpt", "arppu"].includes(this.statisticsFiltersService.revenueValuesToDisplay[i].value)) {
                value = this.totalPipe.transform(value, true, 2, true);
                value = this.currencySimbolPipe.transform("USD") + value;
            } else {
                value = this.totalPipe.transform(value, true, 0);
            }

            this.statisticsFiltersService.revenueValuesToDisplay[i].secondLabel = value;
        }
    }
}
