import { Component, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-copy-to-clipboard-text",
    templateUrl: "./copy-to-clipboard-text.component.html",
    styleUrls: ["./copy-to-clipboard-text.component.scss"],
})
export class CopyToClipboardTextComponent {
    @Input() text: string;
    @Input() copiedText: string;
    @Input() disabled: boolean = false;

    constructor(private snackBar: MatSnackBar) {}

    copyToClipboard() {
        const el = document.createElement("textarea");
        el.value = this.text;
        document.body.appendChild(el);
        el.select();

        try {
            document.execCommand("copy");
        } catch (err) {}

        document.body.removeChild(el);

        this.snackBar.open(this.copiedText, null, {
            duration: 3000,
            panelClass: ["success-snackbar"],
            horizontalPosition: "center",
        });
    }
}
