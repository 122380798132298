import { Injectable } from "@angular/core";
import { RequestParameters, APIRequestsService } from "@app/core/services/api.requests.service";
import { UserService } from "@app/core/services/user.service";
import { AutocompleteEntry } from "@app/shared/autocomplete/autocomplete.component";
import { SelectEntry } from "@app/shared/select/select.component";
import { TimeFilteredService } from "@app/core/services/time-filtered-service";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { SubscriptionService } from "@app/core/services/subscription.service";

@Injectable({
    providedIn: "root",
})
export class StatisticsFiltersService extends TimeFilteredService {
    dataFilters: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    app: BehaviorSubject<AutocompleteEntry> = new BehaviorSubject<AutocompleteEntry>(null);
    country: BehaviorSubject<AutocompleteEntry> = new BehaviorSubject<AutocompleteEntry>(null);

    revenueValuesToDisplay: SelectEntry[] = [
        { value: "grossVolume", label: "statistics.grossVolume", secondLabel: "" },
        { value: "volume", label: "statistics.volume", secondLabel: "" },
        { value: "users", label: "statistics.uniqueUsers", secondLabel: "" },
        { value: "transactions", label: "statistics.transactions", secondLabel: "" },
        { value: "arppu", label: "statistics.arppu", secondLabel: "" },
        { value: "arpt", label: "statistics.arpt", secondLabel: "" },
        // { value: "newUsers", label: "statistics.newUsers", secondLabel: "" },
    ];

    revenueValueToDisplay: BehaviorSubject<SelectEntry> = new BehaviorSubject<SelectEntry>(this.revenueValuesToDisplay[0]);
    loadingApps: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    apps: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    revenueAggregationOptions = [
        {
            label: "statistics.day",
            value: "day",
        },
        {
            label: "statistics.month",
            value: "month",
        },
    ];

    revenueAggregationSelectedOption: BehaviorSubject<any> = new BehaviorSubject<any>(this.revenueAggregationOptions[0]);
    yesterday: Date = new Date(moment().subtract(1, "days").toDate());

    constructor(public requestsService: APIRequestsService, private userService: UserService, private subscriptionService: SubscriptionService) {
        super("yesterday");
        this.init();
    }

    reset() {
        this.updateDates("week");
        this.app.next(null);
        this.country.next(null);
        this.revenueValueToDisplay.next(this.revenueValuesToDisplay[0]);
        this.revenueAggregationSelectedOption.next(this.revenueAggregationOptions[0]);
        this.init();
    }

    init() {
        const loggedUserSubscription = this.userService.loggedUser.subscribe({
            next: (x: any) => {
                if (x) {
                    this.userSignupDate.next(moment(x.signupTimestamp).toDate());
                }
            },
        });

        this.revenueValueToDisplay.next(this.revenueValuesToDisplay[0]);
        this.loadApps();

        const dateFiltersSubscription = this.dateFilters.subscribe({
            next: (dateRange: any) => {
                let diff = Math.abs(dateRange["to"].getTime() - dateRange["from"].getTime());
                var diffInDays = Math.ceil(diff / (1000 * 3600 * 24));

                if (dateRange["to"].getMonth() == dateRange["from"].getMonth() && dateRange["to"].getFullYear() == dateRange["from"].getFullYear()) {
                    this.revenueAggregationSelectedOption.next(this.revenueAggregationOptions[0]);
                }

                if (diffInDays > 92) {
                    this.revenueAggregationSelectedOption.next(this.revenueAggregationOptions[1]);
                }

                return false;
            },
        });

        this.subscriptionService.addSubscription(loggedUserSubscription);
        this.subscriptionService.addSubscription(dateFiltersSubscription);
    }

    updateApp(_value: any) {
        if (_value == this.app.value) {
            return;
        }

        this.app.next(_value);
        this.notifyDataChange();
    }

    updateCountry(_value: any) {
        if (_value == this.country.value) {
            return;
        }

        this.country.next(_value);
        this.notifyDataChange();
    }

    updateRevenueAggregation(_value: any) {
        if (_value == this.revenueAggregationSelectedOption.value) {
            return;
        }

        this.revenueAggregationSelectedOption.next(_value);
    }

    private notifyDataChange() {
        this.revenueValueToDisplay.next(this.revenueValuesToDisplay[0]);
        this.dataFilters.next({
            country: this.country.value,
            app: this.app.value,
        });
    }

    getDataFiltersRequestHeaders(isCombinedRevenue: boolean) {
        let parameters: RequestParameters[] = [];
        parameters.push({
            label: "asPartner",
            data: true,
        });
        if (this.app.value) {
            parameters.push({
                label: "packageNames",
                data: this.app.value.value,
            });
        }
        if (this.country.value) {
            parameters.push({
                label: "countryCodes",
                data: this.country.value.value,
            });
        }
        if (this.revenueAggregationSelectedOption.value && isCombinedRevenue) {
            parameters.push({
                label: "aggregation",
                data: this.revenueAggregationSelectedOption.value.value,
            });
        }
        return parameters;
    }

    loadApps() {
        this.loadingApps.next(true);

        this.requestsService
            .get(`/api/applications`, [
                {
                    label: "accountApplicationStatus",
                    data: "APPROVED",
                },
                {
                    label: "withLiveVersions",
                    data: "true",
                },
            ])
            .subscribe({
                next: (response: any) => {
                    const formattedResponse = response.map((app: any, index) => {
                        return {
                            id: app?.accountApplication?.id,
                            label: app?.accountApplication?.extras?.title,
                            value: app?.application?.name,
                            iconUrl: app?.accountApplication?.extras?.iconUrl,
                        };
                    });

                    this.apps.next(formattedResponse);
                    this.loadingApps.next(false);
                },
                error: (error) => {
                    this.apps.next([]);
                    this.loadingApps.next(false);
                },
            });
    }
}
