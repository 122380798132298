import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class MainService {

    constructor(private router: Router) {}

    routeTo(routes: string[]) {
        this.router.navigate(routes);
    }
}
