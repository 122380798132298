import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DashboardTopTableEntry } from "../utils";
import { StatisticsFiltersService } from "../statistics-filters.service";
import { switchMap } from "rxjs/operators";
import { APIRequestsService, RequestParameters } from "@app/core/services/api.requests.service";
import { DataService } from "@app/core/services/data-service";
import { SubscriptionService } from "@app/core/services/subscription.service";

@Injectable({
    providedIn: "root",
})
export class StatisticsRevenueTopCountriesDataService extends DataService {
    fetch = new BehaviorSubject<boolean>(true);
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    topCountries: BehaviorSubject<DashboardTopTableEntry[]> = new BehaviorSubject<DashboardTopTableEntry[]>([]);
    data: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private statisticsFiltersService: StatisticsFiltersService, 
        private requestsService: APIRequestsService,
        private subscriptionService: SubscriptionService
    ) {
        super(1);
    }

    init() {
        const dateFiltersSubscription = this.statisticsFiltersService.dateFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        const dataFiltersSubscription = this.statisticsFiltersService.dataFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        const revenueValueToDisplaySubscription = this.statisticsFiltersService.revenueValueToDisplay.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        const fetchSubscription = this.fetch
            .pipe(
                switchMap((x) => {
                    if (!x) {
                        return;
                    }
                    return this.getData();
                })
            )
            .subscribe({
                next: (data: any) => {
                    this.data.next(data);

                    const top: DashboardTopTableEntry[] = [];
                    for (const key in data) {
                        top.push(new DashboardTopTableEntry(top.length + 1, data[key].identifiers.country.code, data[key].identifiers.country.code, data[key].volumeInUsd, "USD"));
                    }
                    this.topCountries.next(top);
                    this.formatData();
                    this.loadingData.next(false);
                },
                error: (_error: any) => {
                    this.topCountries.next([]);
                    this.loadingData.next(false);
                },
            });

        this.subscriptionService.addSubscription(dateFiltersSubscription);
        this.subscriptionService.addSubscription(dataFiltersSubscription);
        this.subscriptionService.addSubscription(revenueValueToDisplaySubscription);
        this.subscriptionService.addSubscription(fetchSubscription);
    }

    formatData() {
        this.loadingData.next(true);

        if (this.data.value) {
            let top: DashboardTopTableEntry[] = [];
            const value: string = (() => {
                if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "grossVolume") {
                    return "volume";
                }

                return this.statisticsFiltersService.revenueValueToDisplay.value.value;
            })();

            for (let key in this.data.value) {
                top.push(
                    new DashboardTopTableEntry(
                        top.length + 1,
                        this.data.value[key].identifiers.country.code,
                        this.data.value[key].identifiers.country.code,
                        this.data.value[key][value],
                        ["volume", "arpt", "arppu"].includes(value) ? "USD" : ""
                    )
                );
            }
            top = top.sort((a, b) => (a.value < b.value ? 1 : -1));

            this.topCountries.next(top);
            this.loadingData.next(false);
        }
        this.loadingData.next(false);
    }

    getData() {
        this.loadingData.next(true);
        const parameters: RequestParameters[] = [
            ...this.statisticsFiltersService.getDateFiltersRequestHeaders(),
            ...this.statisticsFiltersService.getDataFiltersRequestHeaders(false),
            {
                label: "type",
                data: "revenue",
            },
            {
                label: "groupBy",
                data: "country",
            },
            {
                label: "order",
                data: "DESC",
            },
            {
                label: "limit",
                data: 100,
            },
            {
                label: "offset",
                data: 0,
            },
        ];
        const sortParameters: any = (() => {
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "transactions") {
                parameters.push({
                    label: "sort",
                    data: "transactions",
                });
                return;
            }
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "volume") {
                parameters.push({
                    label: "sort",
                    data: "usd_revenue",
                });
                return;
            }
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "users") {
                parameters.push({
                    label: "sort",
                    data: "wallets",
                });
                return;
            }
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "newUsers") {
                parameters.push({
                    label: "sort",
                    data: "newUsers",
                });
                return;
            }
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "arppu") {
                parameters.push({
                    label: "sort",
                    data: "arppu",
                });
                parameters.push({
                    label: "amountType",
                    data: "global",
                });
                return;
            }
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "grossVolume") {
                parameters.push({
                    label: "sort",
                    data: "usd_revenue",
                });
                parameters.push({
                    label: "amountType",
                    data: "global",
                });
                return;
            }
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "arpt") {
                parameters.push({
                    label: "sort",
                    data: "arpt",
                });
                parameters.push({
                    label: "amountType",
                    data: "global",
                });
                return;
            }
        })();
        return this.requestsService.get("/api/statistics/top", parameters, true);
    }

    getDataForCSV(limit: number, offset: number) {
        const parameters: RequestParameters[] = [
            ...this.statisticsFiltersService.getDateFiltersRequestHeaders(),
            ...this.statisticsFiltersService.getDataFiltersRequestHeaders(false),
            {
                label: "type",
                data: "revenue",
            },
            {
                label: "groupBy",
                data: "country",
            },
            {
                label: "order",
                data: "DESC",
            },
            {
                label: "limit",
                data: limit,
            },
            {
                label: "offset",
                data: offset,
            },
        ];

        return this.requestsService.get("/api/statistics/top", parameters);
    }

    getDataForCSVGlobal(limit: number, offset: number) {
        const parameters: RequestParameters[] = [
            ...this.statisticsFiltersService.getDateFiltersRequestHeaders(),
            ...this.statisticsFiltersService.getDataFiltersRequestHeaders(false),
            {
                label: "type",
                data: "revenue",
            },
            {
                label: "groupBy",
                data: "country",
            },
            {
                label: "order",
                data: "DESC",
            },
            {
                label: "limit",
                data: limit,
            },
            {
                label: "offset",
                data: offset,
            },
            {
                label: "amountType",
                data: "global"
            }
        ];

        return this.requestsService.get("/api/statistics/top", parameters);
    }
}
