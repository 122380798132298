import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UserService } from "@app/core/services/user.service";
import { UtilsService } from "@app/core/services/utils.service";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { CountriesService } from "./core/services/countries.service";
import { IndicativeService } from "./core/services/indicative.service";
import { AppsListService } from "./core/services/apps-list.service";

@Component({
    selector: "aptd-root",
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {

    constructor(
        private translate: TranslateService,
        private router: Router,
        private userService: UserService,
        private utilsService: UtilsService,
        private indicativeService: IndicativeService,
        private dialog: MatDialog,

        /* SERVICES ARE INCLUDED BELOW FOR PRE-LOADING PURPOSES */
        private appsListService: AppsListService,
        private countriesService: CountriesService,
        private appListService: AppsListService,

        /* SERVICES ARE INCLUDED ABOVE FOR PRE-LOADING PURPOSES */

        @Inject(DOCUMENT) private doc: any
    ) {}

    ngOnInit() {
        this.utilsService.init();

        if (environment.production) {
            this.router.events.subscribe(() => {
                if (typeof (<any>window).Intercom !== "undefined") {
                    (<any>window).Intercom("update");
                }
            });
        }

        this.indicativeService.sendEvent(`partners_console_impression`);
    }

    getDefaultRangeDate() {
        return [moment().subtract(7, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
    }
}
