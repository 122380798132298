import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class SubscriptionService {

    private subscriptionsList: Subscription[] = []
    
    constructor() {}

    addSubscription(subscription) {
        this.subscriptionsList.push(subscription);
    }

    unsubscribeAll() {
        this.subscriptionsList.forEach(subscription => {
            subscription.unsubscribe()
        });
        this.subscriptionsList = [];
    }
}
