import { NgModule } from "@angular/core";
import {
    AppIconPipe,
    AppNamePipe,
    CatappultDateAndTimePipe,
    CatappultDatePipe,
    CatappultTimePipe,
    CountryCodeFlagIconUrlPipe,
    CountryIconPipe,
    CountryNamePipe,
    CurrencySimbolPipe,
    DisplayTypePipe,
    IconByUidPipe,
    IsApprovedPipe,
    IsCurrencyPipe,
    IsDebitPipe,
    IsPendingPipe,
    IsRejectedPipe,
    ShowPackageInfoPipe,
    ToLocaleStringPipe,
    TotalPipe,
    PartnerDisplayNamePipe,
    ShowIfDateMonthAndYearAreDiff,
    ShowIfUserHasPermission,
    AppTitlePipe
} from "./pipes";

@NgModule({
    declarations: [
        TotalPipe,
        IsCurrencyPipe,
        CatappultTimePipe,
        CatappultDatePipe,
        CatappultDateAndTimePipe,
        IsDebitPipe,
        IsApprovedPipe,
        IsPendingPipe,
        IsRejectedPipe,
        DisplayTypePipe,
        ShowPackageInfoPipe,
        ToLocaleStringPipe,
        CountryCodeFlagIconUrlPipe,
        CountryNamePipe,
        IconByUidPipe,
        CountryIconPipe,
        CountryNamePipe,
        CountryIconPipe,
        AppNamePipe,
        AppIconPipe,
        CurrencySimbolPipe,
        PartnerDisplayNamePipe,
        ShowIfDateMonthAndYearAreDiff,
        ShowIfUserHasPermission,
        AppTitlePipe
    ],
    imports: [],
    exports: [
        TotalPipe,
        IsCurrencyPipe,
        CatappultTimePipe,
        CatappultDatePipe,
        CatappultDateAndTimePipe,
        IsDebitPipe,
        IsApprovedPipe,
        IsPendingPipe,
        IsRejectedPipe,
        DisplayTypePipe,
        ShowPackageInfoPipe,
        ToLocaleStringPipe,
        CountryCodeFlagIconUrlPipe,
        CountryNamePipe,
        IconByUidPipe,
        CountryIconPipe,
        CountryNamePipe,
        CountryIconPipe,
        AppNamePipe,
        AppIconPipe,
        CurrencySimbolPipe,
        PartnerDisplayNamePipe,
        ShowIfDateMonthAndYearAreDiff,
        ShowIfUserHasPermission,
        AppTitlePipe
    ],
    providers: [
        TotalPipe,
        IsCurrencyPipe,
        CatappultTimePipe,
        CatappultDatePipe,
        CatappultDateAndTimePipe,
        IsDebitPipe,
        IsApprovedPipe,
        IsPendingPipe,
        IsRejectedPipe,
        DisplayTypePipe,
        ShowPackageInfoPipe,
        ToLocaleStringPipe,
        CountryCodeFlagIconUrlPipe,
        CountryNamePipe,
        IconByUidPipe,
        CountryIconPipe,
        CountryNamePipe,
        CountryIconPipe,
        AppNamePipe,
        AppIconPipe,
        CurrencySimbolPipe,
        PartnerDisplayNamePipe,
        ShowIfDateMonthAndYearAreDiff,
        ShowIfUserHasPermission,
        AppTitlePipe
    ],
})
export class PipesModule {}
