<div class="account-holder-details">
    <div *ngIf="loadingData | async" class="main-spinner">
        <app-loading-spinner align="horizontal" size="big"></app-loading-spinner>
    </div>
    <div *ngIf="!(loadingData | async)" class="info">
        <div>
            <span class="label">
                <p translate>financialDetails.info.name</p>
                <p>*</p>
            </span>

            <app-text-input
                class="input-text"
                [value]="accountHolderDetailsService.name | async"
                (onChange)="updateName($event)"
                [showSpecificError]="(accountHolderDetailsService.displayErrors | async) && !accountHolderDetailsService.nameIsValid()"
            >
            </app-text-input>
        </div>

        <div>
            <span class="label">
                <p translate>financialDetails.info.address</p>
                <p>*</p>
            </span>
            <app-text-input
                class="input-text"
                [value]="accountHolderDetailsService.address | async"
                (onChange)="updateAddress($event)"
                [showSpecificError]="(accountHolderDetailsService.displayErrors | async) && !accountHolderDetailsService.addressIsValid()"
            >
            </app-text-input>
        </div>

        <div class="city-zip">
            <div>
                <span class="label">
                    <p translate>financialDetails.info.zipCode</p>
                    <p>*</p>
                </span>
                <app-text-input
                    class="input-text"
                    [value]="accountHolderDetailsService.zipCode | async"
                    (onChange)="updateZipCode($event)"
                    [showSpecificError]="(accountHolderDetailsService.displayErrors | async) && !accountHolderDetailsService.zipCodeIsValid()"
                >
                </app-text-input>
            </div>
            <div>
                <span class="label">
                    <p translate>financialDetails.info.city</p>
                    <p>*</p>
                </span>
                <app-text-input
                    class="input-text"
                    [value]="accountHolderDetailsService.city | async"
                    (onChange)="updateCity($event)"
                    [showSpecificError]="(accountHolderDetailsService.displayErrors | async) && !accountHolderDetailsService.cityIsValid()"
                >
                </app-text-input>
            </div>
        </div>

        <div>
            <span class="label">
                <p translate>financialDetails.info.country</p>
                <p>*</p>
            </span>
            <app-autocomplete
                [options]="countriesService.countriesAutocompleteData | async"
                [loading]="countriesService.loadingCountriesData | async"
                [selectedOption]="accountHolderDetailsService.country | async"
                (selectedOptionChange)="updateCountry($event)"
                [showSpecificError]="(accountHolderDetailsService.displayErrors | async) && !accountHolderDetailsService.countryIsValid()"
            ></app-autocomplete>
        </div>

        <div class="data">
            <div>
                <div class="vat-label-tooltip">
                    <span class="label">
                        <p translate>financialDetails.info.vat</p>
                        <p>*</p>
                    </span>
                    <img matTooltipPosition="above" matTooltip="{{ 'financialDetails.info.vatFormat' | translate }}" class="tooltip" src="assets/images/info_tooltip.svg" />
                </div>
                <app-text-input
                    class="input-text"
                    [value]="accountHolderDetailsService.vat | async"
                    specificError="{{ 'financialDetails.info.invalidVat' | translate }}"
                    (onChange)="updateVAT($event)"
                    [showSpecificError]="(accountHolderDetailsService.displayErrors | async) && !(accountHolderDetailsService.vatIsvalid | async)"
                >
                </app-text-input>
            </div>
        </div>
    </div>
    <div *ngIf="accountHolderDetailsService.error | async" class="error-container">
        <p class="red small">
            {{ accountHolderDetailsService.error | async }}
        </p>
    </div>
</div>
