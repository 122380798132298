import { Component, OnDestroy, OnInit } from "@angular/core";
import { CountriesService } from "@app/core/services/countries.service";
import { AccountHolderDetailsService } from "@app/shared/account-information-form/account-information-form.service";
import { UserService } from "@app/core/services/user.service";
import { BehaviorSubject } from "rxjs";
import { IndicativeService } from "@app/core/services/indicative.service";

@Component({
    selector: "app-account-information-form",
    templateUrl: "./account-information-form.component.html",
    styleUrls: ["./account-information-form.component.scss"],
})
export class AccountHolderDetailsComponent implements OnInit, OnDestroy {
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        public accountHolderDetailsService: AccountHolderDetailsService,
        public countriesService: CountriesService,
        public userService: UserService,
        private indicativeService: IndicativeService
    ) {}

    ngOnDestroy(): void {
        this.indicativeService.sendEvent(`account_subscription_payment_close_account_data_form`);
    }

    ngOnInit(): void {
        this.loadingData.next(true);

        this.userService.loggedAccount.subscribe({
            next: (res) => {
                if (res) {
                    this.accountHolderDetailsService.name.next(res.name ? res.name : "");
                    this.accountHolderDetailsService.address.next(res.address ? res.address : "");
                    this.accountHolderDetailsService.zipCode.next(res.zipCode ? res.zipCode : "");
                    this.accountHolderDetailsService.city.next(res.city ? res.city : "");
                    this.accountHolderDetailsService.vat.next(res.vat ? res.vat : "");
                    this.countriesService.countriesAutocompleteData.subscribe({
                        next: (data: any) => {
                            const country = data.find((country) => {
                                return country?.id?.toUpperCase() === this.userService?.loggedAccount?.value?.country?.toUpperCase();
                            });

                            this.accountHolderDetailsService.country.next(country ? country : null);
                        },
                    });

                    this.loadingData.next(false);
                }
            },
        });
    }

    updateName(event: any) {
        this.indicativeService.sendEvent(`account_subscription_payment_typing_name`);

        this.accountHolderDetailsService.name.next(event);
    }

    updateAddress(event: any) {
        this.indicativeService.sendEvent(`account_subscription_payment_typing_address`);

        this.accountHolderDetailsService.address.next(event);
    }

    updateCity(event: any) {
        this.indicativeService.sendEvent(`account_subscription_payment_typing_city`);

        this.accountHolderDetailsService.city.next(event);
    }

    updateZipCode(event: any) {
        this.indicativeService.sendEvent(`account_subscription_payment_zipCode`);

        this.accountHolderDetailsService.zipCode.next(event);
    }

    updateVAT(event: any) {
        this.indicativeService.sendEvent(`account_subscription_payment_typing_VAT`);
        this.accountHolderDetailsService.vat.next(event);
    }

    updateCountry(event: any) {
        this.indicativeService.sendEvent(`account_subscription_payment_typing_country`);

        this.accountHolderDetailsService.error.next(null);
        this.accountHolderDetailsService.country.next(event);
    }
}
