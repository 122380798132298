import { Component, Input, Output, EventEmitter, OnChanges, ViewChild, OnInit, OnDestroy, AfterViewChecked } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { BehaviorSubject, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

export interface AutocompleteEntry {
    id: string;
    label: string;
    iconUrl: string;
    value: string;
    category?: string;
    subCategory?: string;
    extra?: any;
}
@Component({
    selector: "app-autocomplete",
    templateUrl: "./autocomplete.component.html",
    styleUrls: ["./autocomplete.component.scss"],
})
export class AutocompleteComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
    @Output() selectedOptionChange = new EventEmitter<AutocompleteEntry>();

    @Input() options: AutocompleteEntry[] = [];
    @Input() selectedOption: AutocompleteEntry = null;

    formControl = new UntypedFormControl();
    filteredOptions = new BehaviorSubject<AutocompleteEntry[]>([]);
    isOpen: boolean = false;

    @Input() leftAlign: boolean = false;
    @Input() showClear: boolean = true;
    @Input() purple: boolean = false;
    @Input() mandatory: boolean = false;

    @Input() showLabel: boolean = false;
    @Input() label: string = null;

    @Input() showPlaceholder: boolean = false;
    @Input() placeholder: string = "";

    @Input() showError: boolean = false;
    @Input() error: string = null;
    @Input() showSpecificError: boolean = false;

    @Input() loading: boolean = false;
    @Input() disabled: boolean = false;
    @Input() readOnly: boolean = false;

    @Input() maxOptions: number = 500;

    @Input() onlyUpdateOnOptionClick: boolean = false;
    @Input() extraPropToMatchBy: string = "";
    @Input() hideIcon: boolean = false;
    @Input() roundedBorders: boolean = false;
    @Input() mobileFilter: boolean = false;
    @Input() showSelectedOption = true;
    @Input() showSelectedOptionIcon = false;
    @Input() noBorderRadiusRight = false;
    @Input() attachOnRight = false;

    @Input() big = false;
    @Input() veryBig = false;

    @Input() country = false;
    @Input() package = false;
    @Input() partner = false;

    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

    @ViewChild("autoCompleteInput", { read: MatAutocompleteTrigger })
    autoComplete: MatAutocompleteTrigger;

    @ViewChild("autoCompleteInput") autoCompleteInput: any;

    subscription: Subscription = null;

    constructor(public translate: TranslateService) {
        this.formControl.valueChanges.subscribe({
            next: (value: any) => {
                if (!value) {
                    this.refreshFilteredOptions(null);
                    return;
                }

                if (typeof value === "string") {
                    this.refreshFilteredOptions(value);
                    if (!this.onlyUpdateOnOptionClick) {
                        this.selectedOption = this.options.find((option) => {
                            if (option?.label?.toLowerCase() === value?.toLowerCase()) {
                                this.filteredOptions.next([]);
                                return true;
                            }
                        });
                        this.selectedOptionChange.next(this.selectedOption);

                        if (!this.showSelectedOption) {
                            this.selectedOption = null;
                            this.formControl.setValue(null, { emitEvent: false });
                        }
                    }
                    return;
                }

                if (JSON.stringify(value) !== JSON.stringify(this.selectedOption)) {
                    this.selectedOption = this.options.find((option) => {
                        if (JSON.stringify(option) === JSON.stringify(value)) {
                            this.filteredOptions.next([]);
                            return true;
                        }
                    });

                    this.filteredOptions.next([]);
                    this.selectedOptionChange.next(this.selectedOption);
                    if (!this.showSelectedOption) {
                        this.selectedOption = null;
                        this.formControl.setValue(null, { emitEvent: false });
                    }
                }
            },
        });
    }

    ngOnInit() {
        window.addEventListener(
            "scroll",
            () => {
                if (this.autoComplete?.panelOpen) {
                    this.autoComplete.updatePosition();
                }
            },
            true
        );
    }

    ngOnChanges() {
        if (this.selectedOption) {
            if (JSON.stringify(this.selectedOption) !== JSON.stringify(this.formControl.value)) {
                this.formControl.setValue(this.selectedOption, { emitEvent: true });
            }
        } else {
            this.formControl.setValue(null, { emitEvent: true });
        }

        if (this.disabled || this.readOnly) {
            this.formControl.disable({ onlySelf: true });
        } else {
            this.formControl.enable({ onlySelf: true });
        }
    }

    ngAfterViewChecked() {
        if (this.autocomplete && !this.subscription) {
            this.subscription = this.autocomplete.panelClosingActions.subscribe({
                next: () => {
                    if (!this.selectedOption) {
                        this.isOpen = false;
                        this.autoCompleteInput.nativeElement.value = null;
                        this.autoCompleteInput.nativeElement.dispatchEvent(new Event("input", {}));
                    }
                },
            });
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    refreshFilteredOptions(value: string) {
        if (this.loading) {
            this.filteredOptions.next([]);
            return;
        }

        if (value) {
            let filteredOptions = this.options
                .filter((option) => {
                    return (
                        option?.label?.toLowerCase()?.indexOf(value?.toLowerCase()) >= 0 ||
                        (this.extraPropToMatchBy && option?.[this.extraPropToMatchBy]?.toLowerCase()?.indexOf(value?.toLowerCase()) >= 0)
                    );
                })
                .slice(0, this.maxOptions);
            if (filteredOptions.length === 0) {
                filteredOptions = this.options.filter((option) => option?.value?.toLowerCase()?.indexOf(value?.toLowerCase()) >= 0).slice(0, this.maxOptions);
            }

            this.filteredOptions.next(filteredOptions);
            return;
        }
        this.filteredOptions.next(this.options.slice(0, this.maxOptions));
    }

    displayWith(_value: AutocompleteEntry) {
        return _value ? _value.label : null;
    }

    clear() {
        this.formControl.setValue(null, { emitEvent: true });
        this.selectedOption = null;
        this.refreshFilteredOptions(null);
        if (this.showSelectedOption) {
            this.selectedOptionChange.next(this.selectedOption);
        }
    }
}
