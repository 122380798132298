<div class="big-desktop-version">
    <div 
      class="copy-to-clipboard-container" 
      (click)="$event.stopPropagation(); copyToClipboard()" 
      matTooltip="{{ 'copy-to-clipboard' | translate }}" 
      #tooltip="matTooltip"
      [class.disabled]="disabled"
    >
      <p 
        class="xxs semiBold"
        [class.disabled]="disabled"
      >
        {{ text }}
      </p>

      <img [src]="disabled ? '/assets/images/copy-to-clipboard-disabled.svg' : '/assets/images/copy-to-clipboard.svg' " />
  </div>
</div>

<div class="big-mobile-version">
  <div 
    class="copy-to-clipboard-container" 
    (click)="$event.stopPropagation(); copyToClipboard()" 
    [class.disabled]="disabled"
  >
  <p 
    class="xxs semiBold"
    [class.disabled]="disabled"
  >
    {{ text }}
  </p>

    <img [src]="disabled ? '/assets/images/copy-to-clipboard-disabled.svg' : '/assets/images/copy-to-clipboard.svg' " />
  </div>
</div>
