import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CopyToClipboardTextComponent } from "./copy-to-clipboard-text.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    TranslateModule
  ],
  declarations: [CopyToClipboardTextComponent],
  exports: [CopyToClipboardTextComponent],
})
export class CopyToClipboardTextModule {}
