import { Injectable } from "@angular/core";
import { APIRequestsService, RequestParameters } from "@app/core/services/api.requests.service";
import { CURRENCIES } from "@app/core/services/constants";
import { UserService } from "@app/core/services/user.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class WalletMainService {
    currencies: any = CURRENCIES;
    loadingBalance = new BehaviorSubject<boolean>(true);
    balance = new BehaviorSubject<number>(null);
    currency: BehaviorSubject<string> = new BehaviorSubject<string>(this.userService.loggedAccount.value["withdrawRequests.currency"] ? this.userService.loggedAccount.value["withdrawRequests.currency"] : this.currencies[0].value);
    usdBalance: BehaviorSubject<number> = new BehaviorSubject<number>(null);


    constructor(private requestsService: APIRequestsService, private userService: UserService) {
        this.getBalance();
    }

    reset() {
        this.getBalance();
    }

    getBalance() {
        this.loadingBalance.next(true);
        this.requestsService.get("/api/wallet", [], true).subscribe({
            next: (res: any) => {
                this.usdBalance.next(res?.balance.usd);
                this.balance.next(res?.balance.usd);
                if (this.currency.value !== "USD") {
                    this.getUsdToCurrencyExchangeRate();
                }
                this.loadingBalance.next(false);
            },
        });
    }

    getUsdToCurrencyExchangeRate() {
        const parameters: RequestParameters[] = [];
        parameters.push({ label: "from", data: "USD" });
        parameters.push({ label: "to", data: this.currency.value });
        this.requestsService.get("/api/wallet/currencyRate", parameters, true).subscribe({
            next: (res: number) => {
                this.balance.next(this.usdBalance.value * res);
            },
        });
    }

    getUsdToCurrencyExchangeRateObservable() {
        const parameters: RequestParameters[] = [];
        parameters.push({ label: "from", data: "USD" });
        parameters.push({ label: "to", data: this.currency.value });
        return this.requestsService.get("/api/wallet/currencyRate", parameters, true);
    }
}
